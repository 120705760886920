@import "bootstrap/scss/bootstrap.scss";
body {
	background-color: #00091a;
	color: #eee;
}
.tablehead{
  background-color:#031627;
  color: #eee;
}
.opacity-90 {
  opacity: .9;
}

#MatchTable {
  tbody > tr:nth-child(odd) > td {
    background-color: #17a389;
  }

  tbody > tr:nth-child(even) > td {
    background-color: rgba(6, 124, 92, 255);
  }

}
.goalCss { 
	background-color: #10540A!important;
  color:#4FFF41 !important;
  font-weight: bolder;
  font-size: larger;
}
.dangerCss { 
  color:darkorange !important;
  font-weight: bolder;
  font-size: larger;
}

